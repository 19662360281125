import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Internet_Access/DDNS_Provider/NavButtons';
import ForumBox from 'components/Internet_Access/DDNS_Provider/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "DDNS Provider - Selfhost.de",
  "path": "/Internet_Access/DDNS_Provider/Selfhost/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Use a Third Party DDNS Provider with your INSTAR IP Camera",
  "image": "./IA_SearchThumb_ThirdPartyDDNS_selfhost.png",
  "social": "/images/Search/IA_SearchThumb_ThirdPartyDDNS_selfhost.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_IA-DDNS_white.webp",
  "chapter": "Remote Access"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='DDNS Provider - Selfhost.de' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use a Third Party DDNS Provider with your INSTAR IP Camera' image='/images/Search/IA_SearchThumb_ThirdPartyDDNS_selfhost.png' twitter='/images/Search/IA_SearchThumb_ThirdPartyDDNS_selfhost.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Fernzugriff/DDNS_Anbieter/Selfhost/' locationFR='/fr/Internet_Access/DDNS_Provider/Selfhost/' crumbLabel="Selfhost" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "ddns-provider",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#ddns-provider",
        "aria-label": "ddns provider permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DDNS Provider`}</h1>
    <h2 {...{
      "id": "selfhostde--managed-ddns-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#selfhostde--managed-ddns-service",
        "aria-label": "selfhostde  managed ddns service permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Selfhost.de :: Managed DDNS Service`}</h2>
    <p>{`How to use your Selfhost address with your INSTAR IP camera?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/98e699c80ba201d9b6682a0ff26d166b/8c557/Selfhost.de_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACpElEQVQozw3RW09ScQAA8PPQQ0/13NrqudZ3qLW1uZpry1bZZdhjW7asiVlZm7nMlaWm1VLBG2agggjGRS56uBoXDxyEw/1wLh4OBwQEEYHzr99X+EH7HFdBkHI0mitWDgGoA74B+HqjdlDeZ9hcNJXmWC6bzVkcgY/vvqAub5lKZcMYgSa2vRRUCIcLbncORamFxcynweSmOWiBow4bZoHtq2qzXOVeW/No9Ytfv79svW+YleRcDsKgj2pMoQ0X5FapdvR6zGTM2KyZDXNIv07/dQU02qBWXwoFOQRhvL641bGt0YYttojVHjKZMZPJJpVFjEbIFwqx+UKcpnkAFtSrl5qbJatKpWisR3DPYnUeVqvVVKxE4nmKTOMEixN0oZQGYI/neQAgP4LYrZYtu42v16WK5YvnzwrvNHW2Xu+9cVlwrSnJsDROcSkiv8swOMngJMmV/GR+Z3e/Ua9D+LiYEs8Qn4cBzaxbYOHdqxPtt0+dODk5+o2lowaMmcNqqkTVRB7BVBXGy7poecoSlzlitXoDCvpw1JP0OKN8A0zPjM+PDHY9aBnoeZ/C6B9jQ0PyjWkkJ3EkpI7YL2tYvLUrUBbbZKQ/BxqNBuRFaf8OE8AyPABqrbr97YfH3f22zeCEaOlKR+9rY0rk4kReVoyVhmH8+STc1Klo6RA7UarOA4iQ6/Jq44F0BaQzSDj+82n37BOhSmdd1sALL/pkI1NKJCGdU8qevfm9pNNobaPCvv5HXQbleg0ACHf495we0u6t5gsAgOKtm5kL5444rkyS7JnTRNvDAgB7kvn08WOEQlUBYGReIXg1kCxUAABQ1YfmTWYMDfC12mH5gPqjI6ZmSrFEOlsMydeCOjh9BIgIHpyThf2RbA1sIuEVvS3JFv9X/QN1pPw/7N0GQQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98e699c80ba201d9b6682a0ff26d166b/e4706/Selfhost.de_01.avif 230w", "/en/static/98e699c80ba201d9b6682a0ff26d166b/d1af7/Selfhost.de_01.avif 460w", "/en/static/98e699c80ba201d9b6682a0ff26d166b/70e80/Selfhost.de_01.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/98e699c80ba201d9b6682a0ff26d166b/a0b58/Selfhost.de_01.webp 230w", "/en/static/98e699c80ba201d9b6682a0ff26d166b/bc10c/Selfhost.de_01.webp 460w", "/en/static/98e699c80ba201d9b6682a0ff26d166b/426ac/Selfhost.de_01.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/98e699c80ba201d9b6682a0ff26d166b/81c8e/Selfhost.de_01.png 230w", "/en/static/98e699c80ba201d9b6682a0ff26d166b/08a84/Selfhost.de_01.png 460w", "/en/static/98e699c80ba201d9b6682a0ff26d166b/8c557/Selfhost.de_01.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/98e699c80ba201d9b6682a0ff26d166b/8c557/Selfhost.de_01.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Confirm that you want to get the free account.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/129c4ca363d41a8f6b26220868fb2a43/8c557/Selfhost.de_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.043478260869556%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACWUlEQVQoz12Qy08TYRTF5w8xYWHin+DWjQswUSGIiW40mhAhghJrokHeRjCg0UIwBQRSUIgIFiN2IZAI4VXsC1pb2s5MoZ1OSx/T6XzzzbTzuGaqROMvJzd3c849ucS3yxcuVV980Fjf395iX1rMYv0wJydlSB0XoiHGF0zuBZPecMYZzvoCrP8g9SNR8mQ0VtR4BQj/cG9oxPz1dbul997zxivxBJPAEJcglcjm3P7cXjDj+Rn3kdQBQ3kjpMP/nRa3WCXKFdOiQsTn34bHhlzWvoMFi2u4O+53AICuaTrAv1IBkAp5FThRRpKCS2pRUQm3pXf15VNbf3NsbpSeHaI3bIAE3QjQQdegjKLrRU3DiprHJUkzsn5DMBS1NjPitY/aOhs3zabYzIC2Nl9CGSgXKFLR3LaT23Vzrn3e4+N3XUXPHgQCwLIgy0RRB5kJF1YmIlM9oalngm1c2vyAqS0jHospp88XYtJpkU0WSPKYiqRjR5y4HwCMjcue/cOdL59S9ukd0/XpiorJU2cc3SZ24zMW8qrAS8vLyaFB8kkrNWg2uqiqMWMxEEXDnM/kCt41oF22hy3XKk6P329Y6mu1djTPvWrbXnxHWsdo60Ri4WN2ZQWOjoCmIRqFcBgUxTBHI/Tme8t6h2mirmaqp3mm59ZAQ+WjurOdN8/Pmjt21+2pDKOefO4/iK6ugdqa2qaqysnHd0Zab7y4W9N5u6rl6rm2+urZN32O1SWGDMpIAE3/a9L/7ARCIsJSQZJELAo8n+e48sjxfL4sXiiDEMInIIQEQVAU5RcuChwNKMR37gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/129c4ca363d41a8f6b26220868fb2a43/e4706/Selfhost.de_03.avif 230w", "/en/static/129c4ca363d41a8f6b26220868fb2a43/d1af7/Selfhost.de_03.avif 460w", "/en/static/129c4ca363d41a8f6b26220868fb2a43/70e80/Selfhost.de_03.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/129c4ca363d41a8f6b26220868fb2a43/a0b58/Selfhost.de_03.webp 230w", "/en/static/129c4ca363d41a8f6b26220868fb2a43/bc10c/Selfhost.de_03.webp 460w", "/en/static/129c4ca363d41a8f6b26220868fb2a43/426ac/Selfhost.de_03.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/129c4ca363d41a8f6b26220868fb2a43/81c8e/Selfhost.de_03.png 230w", "/en/static/129c4ca363d41a8f6b26220868fb2a43/08a84/Selfhost.de_03.png 460w", "/en/static/129c4ca363d41a8f6b26220868fb2a43/8c557/Selfhost.de_03.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/129c4ca363d41a8f6b26220868fb2a43/8c557/Selfhost.de_03.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in a personal host name you want to use for your camera - we choose simply instar.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d67c06d5330162fe7c5d4da652f546ac/8c557/Selfhost.de_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "100.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADo0lEQVQ4y2XSW2/URhQHcH8R+tAHvlE/Qh95oZcnVCUrlVIkpEJVQRJoSVIpgUJSQtM2bBEhkNveEnLZza7tvfqyHtszvuyM7bE9B+0mAqRKP/11NDqjcx6OlF++nDcVAMhR194uopcv5NVl9dnDEy24suFMv7K+KqKvi+hq0b6yrn+x2Pr8N/+zOXxpjlyaI1Ji2VwAjzN6VsK1HbK9ZRb/VZ7O9/rGXIksltCDfXtuFy0c+rfXGjMvezPV8F6J3Ct7d8ueBCAAANI4310Wyq7YfwGV/04XfmD9+vk75LEQCYBA5d0RsgBAiMkXAOl6Rfx4ADdK8TeLb6afn91c7377e+3LW0/ubKPZ4/yXWny7wm+Ws++22LUnZ9NbYaEMhZIolKBQAunqDrpf79497c22zNkzbaap32/pyz1rSdEeKb2n7e7GQF3rqY/a6h/d3uN2+7GqfiAFHNsRUQmWsWtR7EXYZ4hFwxGzQmpRNhSpKbIhgA2AJvmRJHtek3hV5O2ZXh2P60Fg+5HpZW4Ifgihn2GHDu3I+T8p5J2Qd+yog1gnSDpB2vXidggDu/q6/3BRvvVT/9VaCFrAuxOdT0kA5sTwQq4B7wJovLnnrK8YK8thYxdAh6wHYEx6zA+kJQWWVFhqji3LsHoc/fkWPdux12r+eiP6pxGtHdG1fef5vrtS50vypFm5IE0dQuEv/fuV9vW/9Wur2o11bfGNubBl/LppzL/W5zf1n4vGg01zfsu8UzSmV3uFDTRVg6lyPlURkgAQ/X5AmB8DpmLop3kS5TwWPMl5nPNEpGmephnneZ7ljIl+X0zuRABIRgKmoqso6oyg7sIhyltOrAfcZGBSYYxyk4pzBgODxENZM2IwRsKgIOEMcFvXnEij0PZAwRmhsUeTcziMCU08NhFx7FGiDnAKOBI4Bgml4Ki660dmNJ7cdDOXcjuMXcrHWHpRTGpEqK0MEAfEBIpAcjJw27pNIjsBxQN5PJm7owRT/olkjHGHUKxqTgpOJJwYJDcF0tGIz0gKJhMNJ5Wd5MxOzCDxWULoRzhKEA5JW3NTcKPcjcV4bSRrXTvqM2i4cGoLTHOH5i7L8YRLs/GSMVgxWF5itwZWAhYVFgMJJ67deafX3g5P9geNmlavELnqyRVfqZDzbFXsVhUrVdopj5S9kXkSZnaYmCEfSjSuDoLjoVdDpKyFR3rwTvOPet6RFhy57MCPai47dNgBYTWelQFKAOc59h7b3CLHxMq61gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d67c06d5330162fe7c5d4da652f546ac/e4706/Selfhost.de_04.avif 230w", "/en/static/d67c06d5330162fe7c5d4da652f546ac/d1af7/Selfhost.de_04.avif 460w", "/en/static/d67c06d5330162fe7c5d4da652f546ac/70e80/Selfhost.de_04.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d67c06d5330162fe7c5d4da652f546ac/a0b58/Selfhost.de_04.webp 230w", "/en/static/d67c06d5330162fe7c5d4da652f546ac/bc10c/Selfhost.de_04.webp 460w", "/en/static/d67c06d5330162fe7c5d4da652f546ac/426ac/Selfhost.de_04.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d67c06d5330162fe7c5d4da652f546ac/81c8e/Selfhost.de_04.png 230w", "/en/static/d67c06d5330162fe7c5d4da652f546ac/08a84/Selfhost.de_04.png 460w", "/en/static/d67c06d5330162fe7c5d4da652f546ac/8c557/Selfhost.de_04.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d67c06d5330162fe7c5d4da652f546ac/8c557/Selfhost.de_04.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Even though you are going to use a free account, Selfhost still wants to collect some billing information from you in case you change your mind later and pick up a premium account.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/14b35227ce64abb0ee380ccb678e4ee7/8c557/Selfhost.de_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACJ0lEQVQozzWQzWvTYADGc5aBetqfMPDix2EHZYKIguBRLzIRNhg4cOhEQQ/boWOTfQjCVuZAqQ5BRG9eJioobu22bkydXbcmTbI2zdqmSds0zZu8H49kIjzX3/MlobOTfP/hA5xQwRCGwvJExkbaoNm0/GdN3l7PbW0o6VQuvbyzvCInV5RPebJaRdaB1Eiu11qh0wrqbUqooBwCcClqPndLlquXnP0DSzcrcsHMly1FM7J7awd008KvGiQmRBCwsFzzNaOtFgKtwIoGSibMA5hllKvgHIAQAKq57cSbp3126TcAQrkEygAETr0kqwVZd8tWYDvEabKmh6aLekNQekjyUnI63t8T7z2/FB8CmBCQBBcQCMKwatczOdW0nEabEMoCygWiCUJEyWGrkHl9T02MGm/Hv87crlUU4D9MfOK5LWO/6Fi2VakLBnAhKBeUCR5V8x09lXhgvhzZHBt49eh6o6Iewizq5FRtTdH2tUKz5ZbbdTv0XEbwLxmRP+B/nB9avHM5NXXrSf9ZdXej7QcSpwIcnuvlc8rentrQi15ebmkK0TRUHeR1YZQiFlj/MPHu4dWfszfjg5dOnT5xrqdbEgyRqAjbAWGgyTU6+zyYXyDP5pqxcbL0BZksgMHh+xfPdH0b6/s8cWNx4Mrxox0dx45IoIgUCoTRMXRn15ic0qemtcnp3ZHRRnKVKzmvuJOYi72fubv14vG1CydTi7HYcG93d9dfGgj3yHfb3OgAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14b35227ce64abb0ee380ccb678e4ee7/e4706/Selfhost.de_05.avif 230w", "/en/static/14b35227ce64abb0ee380ccb678e4ee7/d1af7/Selfhost.de_05.avif 460w", "/en/static/14b35227ce64abb0ee380ccb678e4ee7/70e80/Selfhost.de_05.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/14b35227ce64abb0ee380ccb678e4ee7/a0b58/Selfhost.de_05.webp 230w", "/en/static/14b35227ce64abb0ee380ccb678e4ee7/bc10c/Selfhost.de_05.webp 460w", "/en/static/14b35227ce64abb0ee380ccb678e4ee7/426ac/Selfhost.de_05.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/14b35227ce64abb0ee380ccb678e4ee7/81c8e/Selfhost.de_05.png 230w", "/en/static/14b35227ce64abb0ee380ccb678e4ee7/08a84/Selfhost.de_05.png 460w", "/en/static/14b35227ce64abb0ee380ccb678e4ee7/8c557/Selfhost.de_05.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/14b35227ce64abb0ee380ccb678e4ee7/8c557/Selfhost.de_05.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`After confirming your information, Selfhost will check if the hostname you choose is available and then lets you finish process via an email confirmation.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/80d58b3031d0f61c25e3175afadde253/8c557/Selfhost.de_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.260869565217384%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB5UlEQVQY00XITW/ScADH8b4OHNlewV6AB40nvZjs4mVmJh5MnA9LMDEx06gHNdlQFzfD1KGr2lYIJGM+4MMIWiY4GB2Mp20hQxgPYywU2g5Kof3/DCeTz+GbL7VjvcFzd3/bb1ZWHqHoUWKOZpiuR5jZYHE6ojyNNGbXG0/CTWu4cetX88pKc/Rz46xHGuZaw1yL8r27x90ecT4Y2/nyMOuaSM2dy9pGt19duPPGfektP/5+9apj7bJDmHBtXHMnrrtTlqUty3L2vHtvbKlC8fSk6/6IbfzUT/ZxMupPxfl84k/V/0397tF4by/wVV/9QYI+IyOQag5HNWgSjBbQBQzK53zmnLHMTV5kX7+QxXIuGdyOBfLRwEGIP1gP1YS1uhAWY1Fpc0PJJKXDfUUS20eyqra7vS5Vk7X9hlqqt6qSJmukKBsFieQlklfIXwUlsdMplNTdfKdc7QAdnWj/gTrtxRkvTn7CiY84voxBFgMMzAyGWDLEEjNDBhgMcjjWn8TMwsTAxMHk6Af1MmHYUwadIZ5dspAiU1GykCbzSVgFTAuYT8KWxJQAexrPN8lMHItpgw7VaX+ZjqtUpbCnKgp0jfRUWRSVeo1024YBtdOn6wAhRg+ADo8bH1ioEuRD5LZgtP8BJid2cKDUpRMAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/80d58b3031d0f61c25e3175afadde253/e4706/Selfhost.de_08.avif 230w", "/en/static/80d58b3031d0f61c25e3175afadde253/d1af7/Selfhost.de_08.avif 460w", "/en/static/80d58b3031d0f61c25e3175afadde253/70e80/Selfhost.de_08.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/80d58b3031d0f61c25e3175afadde253/a0b58/Selfhost.de_08.webp 230w", "/en/static/80d58b3031d0f61c25e3175afadde253/bc10c/Selfhost.de_08.webp 460w", "/en/static/80d58b3031d0f61c25e3175afadde253/426ac/Selfhost.de_08.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/80d58b3031d0f61c25e3175afadde253/81c8e/Selfhost.de_08.png 230w", "/en/static/80d58b3031d0f61c25e3175afadde253/08a84/Selfhost.de_08.png 460w", "/en/static/80d58b3031d0f61c25e3175afadde253/8c557/Selfhost.de_08.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/80d58b3031d0f61c25e3175afadde253/8c557/Selfhost.de_08.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go to Details to check your account information that you will need to update your DDNS hostname.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cb6004eb6f229229b2e2314a04b1ab58/8c557/Selfhost.de_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAByUlEQVQY023KYUsTcRzA8XsX4lsI9QX0rAcxNKGgkIggREwSBhEZKIFPIg18shB0Os+aRkxnB9XcXGubN8Zu8ybbzc2dUxbtbtf03HZ33d3+5+1+sahACD58H32xi+4uZmU66p5KuaekvUWL25GyhMF4cvuROfqnMyMvZ+XFjOLKynN78mRMtn+VH/il64R61atjrOvVtnPC/WzQ5xj/vvOitD5SmL/7bWEo5Hw6jEfH3kTH1uKj68lH79PjG5mHnpzdm39MFO9vlm9vcFiR2oo47Z5JG/5kkA6+YxL+4xzJJUP1iE/d9WlUCFEhRJMXDGXwR2adb2vnYCjQ1gFMjI5tE/iMa8b+cmLEH/gs1sr5NHmYJoV4WCTDQiJWjcfEZOKMTjXolFIuyU1RVRq6piCjhd0KQP8ny/axfe2DecNnDX2BmwEY8EPvFvQQMBCEe7tgC8KdSKdXvNC3Cb1/YatsFWc5nOXflgT3sdBpiV8oCM+Tp9OpH7P7tdl0zcFUV4uVpXzFwVRe57h/MAAJoAEgmSqnnbGaeITUar3JI6MJoIAl/R6a/4XpLejQLa0F+UKZopjMyXn44LRQUeqyiRC0EOgI/myX/QIP+HfItaNdqgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb6004eb6f229229b2e2314a04b1ab58/e4706/Selfhost.de_09.avif 230w", "/en/static/cb6004eb6f229229b2e2314a04b1ab58/d1af7/Selfhost.de_09.avif 460w", "/en/static/cb6004eb6f229229b2e2314a04b1ab58/70e80/Selfhost.de_09.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cb6004eb6f229229b2e2314a04b1ab58/a0b58/Selfhost.de_09.webp 230w", "/en/static/cb6004eb6f229229b2e2314a04b1ab58/bc10c/Selfhost.de_09.webp 460w", "/en/static/cb6004eb6f229229b2e2314a04b1ab58/426ac/Selfhost.de_09.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cb6004eb6f229229b2e2314a04b1ab58/81c8e/Selfhost.de_09.png 230w", "/en/static/cb6004eb6f229229b2e2314a04b1ab58/08a84/Selfhost.de_09.png 460w", "/en/static/cb6004eb6f229229b2e2314a04b1ab58/8c557/Selfhost.de_09.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cb6004eb6f229229b2e2314a04b1ab58/8c557/Selfhost.de_09.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Write down your DYN Account username, password and hostname - you will need those in the next step.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/efb35925000d0803edb32d454c2eb5c0/8c557/Selfhost.de_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.47826086956522%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACJElEQVQoz1XL207bMACA4bw50vYCaBNlTNoFAm1oiLETAsRhjOOg4tiUxE3TNk5iJ7ZzcuwkTlsylXEz6bv4b37tanXuZHmuteMsHY3eHQ5bh6PVc/jj2vvW9jbb3toF3Lr1V07h0i/nP4fO+iXULtZf7398Nf8TLO6b7w/A4p65fGR+v7I2zntfLnprJ+bnM/DhwJjf0lu7xtvtbmvXWNjpvtnWV34bWq7keDppGiXKDBEcCR6XkgjBpGRSkixDhKEkBX6I03QYEpymDmFuFMMo1oBl5zyuaw7DoN3pG0Ok28gc4Yee2xlg+/LW+rTRP2tbiLlh5NFkJkpdlng00vqWHSJccZplCGE3CDxCEGM4nIUfhS7xhyFywtCn1GcMUeKzAPI4UEWinS9/7W3ukZPjnAz902O//YfdX3PjQQBdAj0HurQeS9sorcfCMgqgi6ElRFLMxJrRNVRGJ5O09Mzk4SbCMLBB7I0yDBPfybCXIphh9wWClX5Xq7yueFnEmuOjcV2qSaYCkDt2wHCaEKVyRkMXurKUspSUEkoxowGlQWF26oq/zCyG45rVDamRIfqAMVzm0aTKYhLwKCoFr6RQRf6k+FTlU8UnPX2s+Fjls7lpzGb62DRAEUve3XBTr2xTDcy0ey+AoYb9yrbKPhCmLsyuNHRh6kWRSR7LnGnNU+fZfVW5VRErQatnSrJKkH9KQbE3wO6AoBHyR3GMQgzT1PsLiG5D+xh2nzcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efb35925000d0803edb32d454c2eb5c0/e4706/Selfhost.de_11.avif 230w", "/en/static/efb35925000d0803edb32d454c2eb5c0/d1af7/Selfhost.de_11.avif 460w", "/en/static/efb35925000d0803edb32d454c2eb5c0/70e80/Selfhost.de_11.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/efb35925000d0803edb32d454c2eb5c0/a0b58/Selfhost.de_11.webp 230w", "/en/static/efb35925000d0803edb32d454c2eb5c0/bc10c/Selfhost.de_11.webp 460w", "/en/static/efb35925000d0803edb32d454c2eb5c0/426ac/Selfhost.de_11.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/efb35925000d0803edb32d454c2eb5c0/81c8e/Selfhost.de_11.png 230w", "/en/static/efb35925000d0803edb32d454c2eb5c0/08a84/Selfhost.de_11.png 460w", "/en/static/efb35925000d0803edb32d454c2eb5c0/8c557/Selfhost.de_11.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/efb35925000d0803edb32d454c2eb5c0/8c557/Selfhost.de_11.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To use AVM Fritzbox router to keep your new Selfhost address up-to-date, go to Freigaben/Dynamic DNS, choose selfhost.de from the dropdown menu and type in your account information that you wrote down earlier. Your router will now update your Selfhost address every time your internet service provider assigns you a new WAN IP address.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7560755cab5121d5b6faf8d2ff54cde6/8c557/Selfhost.de_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACc0lEQVQoz22QTU8TURSG5x+YEAgmBNpKm2pUEjAxEfe60D+gohAWiA07oltX/AD+CgtW8mUQCilRorSdj85Mhw6daaG9M3c+7p37ZToYY4hPzuLkJO8573mlzw+nStPTj5/MzszM5O8Ws9lMLlfM3nmQL0zli48yufu5TGZ8fPxesVgsFCYLhexk/tbQ0O2xsYlsTjp98VKen996/mzr6ezR4uLR0lJ5YaH87u1J6UNl+f3h3JtKqVSem6ssL5dfv9ou5L9MTGwMD2+MjGyMjkqnB4fKj+/K4YGyv68cH6snFbVScet1R5adet2pVp20b+v6paK01tbOV1Y6q6vdTx/76+tSc3MT1urYNLlt81Yr0jRQrUVaA+kGtSxqWcSyiKaxKEKU1nVTtloXvb4L/CAhkrG753oQIczFAA8GrbYLYIAIiSnFhAymrisAYEIYmqHU1IasyVUF9IBkbO94QSwY44wNxMDrul0UxygljiIYxfi8JXyfCqEqjXpNU2WteiYDACV9ZzdAmFMq+OB2HEUdp+t7PiWEJAnBOCaE2Pa12G5dtB3Xabse8DnnUnPva8g4DALgQ+AHHgxhFDPOmRBMCJraubadim3TME3TAsBLcCLZ+99irRHKCtIaxDATw0gMgzabzLKYZRGzKWxb6LpAiDLWUA1V1Q29qak6AJ50bp3DXh9cXiEYiCThGHOMGUJ/SySJSGOjhMiy9utnTZUbtTOld9WXOp2OEILz67BvQin92xNCXKcDfQhhEIYR40yybTuOYt+HYRAyxuI4DsMIY8xS/vycwlP+XS21HSfBSRRGCCHGGCEEI5wkCfsfN3z9BsKlioMaZZp0AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7560755cab5121d5b6faf8d2ff54cde6/e4706/Selfhost.de_10.avif 230w", "/en/static/7560755cab5121d5b6faf8d2ff54cde6/d1af7/Selfhost.de_10.avif 460w", "/en/static/7560755cab5121d5b6faf8d2ff54cde6/70e80/Selfhost.de_10.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7560755cab5121d5b6faf8d2ff54cde6/a0b58/Selfhost.de_10.webp 230w", "/en/static/7560755cab5121d5b6faf8d2ff54cde6/bc10c/Selfhost.de_10.webp 460w", "/en/static/7560755cab5121d5b6faf8d2ff54cde6/426ac/Selfhost.de_10.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7560755cab5121d5b6faf8d2ff54cde6/81c8e/Selfhost.de_10.png 230w", "/en/static/7560755cab5121d5b6faf8d2ff54cde6/08a84/Selfhost.de_10.png 460w", "/en/static/7560755cab5121d5b6faf8d2ff54cde6/8c557/Selfhost.de_10.png 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7560755cab5121d5b6faf8d2ff54cde6/8c557/Selfhost.de_10.png",
              "alt": "Third Party DDNS Provider No-IP",
              "title": "Third Party DDNS Provider No-IP",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To use DD-WRT router to keep your new No-IP address up-to-date, go to Setup/DDNS, choose selfhost.de from the dropdown menu and type in your account information. The Update URL is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`https://user:passwort@hostname/nic/update?`}</code></pre></div>
    <p>{`Your router will now update your Selfhost address every time your internet service provider assigns you a new WAN IP address.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      